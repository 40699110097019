<template>
	<div class="box">
		<case-details @getCaseDetailsData="getCaseDetailsData" :caseStates="'edit_view_detail'">
			<template slot="titleBtn">
				<a-button type="primary" @click="SendAudit">{{ $t('operate.sendAudit') }}</a-button>
				<a-button type="primary" @click="EditAudit" style="margin: 0 20px">{{ $t('operate.editAudit') }}</a-button>
				<a-button type="primary" @click="Return">{{ $t('table.applyInfo.tabsMenu.notAccept') }}</a-button>
			</template>
		</case-details>
		<a-modal v-drag-modal :title="$t('table.applyInfo.tabsMenu.notAccept')" v-model="Modal.show" @ok="handleOk" width="800px">
			<a-form-model :label-col="{ span: 2 }" :wrapper-col="{ span: 16 }">
				<a-form-model-item>
					{{ this.$t(`table.applyInfo.msgMap.msg3`) }}
				</a-form-model-item>
				<a-form-model-item :label="$t('table.loanReview.Seeting.modal.remark')">
					<a-input v-model="Modal.checkText" type="textarea" :maxLength="300" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>
<script>
import CaseDetails from '@/views/businessComponents/CaseDetails'
import { apiProcessCheck } from '@/api/check'
export default {
	data() {
		return {
			id: this.$route.query.id,
			Modal: {
				show: false,
				checkText: ''
			},
			columns: [
				{
					title: 'No.',
					dataIndex: 'creditCode',
					width: 150,
					align: 'center',
					customRender: (text, row, index) => {
						return index + 1
					}
				},
				{
					title: 'Warning',
					dataIndex: 'creditDesc',
					align: 'center'
				}
			],
			creditRueModal: false,
			showFooter: true,
			creditRueList: [],
			creditRueListBool: false,
			creditAddRueList: [],
			totalCreditAmount: '',
			dueDateLimit: '',
			processStatus: '',
			loanType: null
		}
	},
	components: {
		CaseDetails
	},
	computed: {
		creditAllRueList() {
			return [...this.creditAddRueList, ...this.creditRueList]
		}
	},
	methods: {
		getCaseDetailsData(val) {
			this.disburseInfo = val.disburseInfo
			this.creditRueListBool = true
			if (val.loanPreCreditResponse) {
				this.creditRueList = val.loanPreCreditResponse.creditRules
				this.totalCreditAmount = val.loanPreCreditResponse.totalCreditAmount
				this.dueDateLimit = val.loanPreCreditResponse.dueDateLimit
			}
			this.processStatus = val.processStatus
			this.loanType = val.loanInfo.loanType
		},
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', '/home/loandata/loanlist')
		},
		SendAudit() {
			if (this.disburseInfo) {
				if (!this.disburseInfo.loanOfferId) {
					this.$message.error('Disbursement Information loan offer number error')
					return false
				} else if (!this.disburseInfo.loanOfferFile) {
					this.$message.error('Disbursement Information loan offer file error')
					return false
				}
				if (this.disburseInfo.carInfos.length === 0) {
					this.$message.error('Disbursement Information Vehicle Information error')
					return false
				}
				if (this.loanType !== 7) {
					let disburseInfoBool = true
					for (let index = 0; index < this.disburseInfo.carInfos.length; index++) {
						const element = this.disburseInfo.carInfos[index]
						if (!element.ntsa.make || !element.ntsa.year || !element.ntsa.frameNumber || !element.ntsa.engineNo) {
							this.$message.error(`Disbursement Information Vehicle Information ${index + 1} NTSA Search basic error`)
							disburseInfoBool = false
							break
						} else if (!element.ntsa.registrationNumber) {
							this.$message.error(`Disbursement Information Vehicle Information ${index + 1} NTSA Search Registration Number error`)
							disburseInfoBool = false
							break
						} else if (!element.ntsaFile) {
							this.$message.error(`Disbursement Information Vehicle Information ${index + 1} NTSA In-charge/Transfer error`)
							disburseInfoBool = false
							break
						}
						if (this.loanType !== 3) {
							if (!element.carTrackingPin) {
								this.$message.error(`Disbursement Information Vehicle Information ${index + 1} Car Tracking Pin error`)
								disburseInfoBool = false
								break
							} else if (!element.trackingCertificate) {
								this.$message.error(`Disbursement Information Vehicle Information ${index + 1} Tracking Certificate error`)
								disburseInfoBool = false
								break
							}
						}
					}
					if (disburseInfoBool === false) {
						return false
					}
				}
				if (this.loanType !== 7) {
					if (this.disburseInfo.afSecurityAgreements.length == 0) {
						this.$message.error('Disbursement Information Security Agreement error')
						return false
					}
					if (!this.disburseInfo.afLoanCalculator) {
						this.$message.error('Disbursement Information Loan Calculatore error')
						return false
					}
				}
			} else {
				this.$message.error('disburseInfo error')
				return false
			}
			this.$confirm({
				title: this.$t('operate.sendAudit'),
				content: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					apiProcessCheck({
						loanId: this.id,
						pass: 0,
						checkType: 'submit',
						// needCredit: 0,
						checkText: '',
						checkFailType: '',
						nodeId: this.processStatus.processNode.id
					}).then((res) => {
						this.$message.success('success')
						this.closeTab(this.$route.fullPath)
						this.$router.push('loanlist')
					})
				},
				onCancel() {}
			})
		},
		Return() {
			this.Modal.show = true
			this.Modal.checkText = ''
		},
		handleOk() {
			if (this.Modal.checkText == '') {
				this.$message.error('remark is required')
				return false
			}
			apiProcessCheck({
				loanId: this.id,
				pass: 0,
				checkType: 'check',
				checkFailType: '0',
				checkText: this.Modal.checkText,
				nodeId: this.processStatus.processNode.id
			}).then((res) => {
				this.$message.success('success')
				this.closeTab(this.$route.fullPath)
				this.$router.push('loanlist')
			})
		},
		EditAudit() {
			this.$router.push({
				path: 'loanadd',
				query: {
					id: this.id
				}
			})
		}
	}
}
</script>
<style scoped lang="less">
.ant-form-item {
	margin-bottom: 0;
}
.anticon-warning {
	margin-left: 20px;
	font-size: 28px;
	color: red;
	vertical-align: middle;
	margin-right: 10px;
}
.rec-loan {
	p {
		margin: 15px 0;
		font-size: 16px;
		.ant-statistic {
			display: inline-block;
		}
	}
}
</style>
